import { Title } from '@solidjs/meta';
import {
	Button,
	Checkbox,
	Errors,
	FieldDescription,
	Heading,
	Input,
	Label,
	TextField,
	TextLink,
	Container,
} from '@troon/ui';
import { useSubmission } from '@solidjs/router';
import { Formspree, formspreeAction } from '@troon/forms';
import { Switch, Match, createSignal } from 'solid-js';
import { Icon } from '@troon/icons';
import { useUser } from '../../providers/user';
import { getConfigValue } from '../../modules/config';

export default function CertificateRedemption() {
	const submission = useSubmission(action);
	const user = useUser();
	const [agreed, setAgreed] = createSignal(false);

	return (
		<>
			<Title>Troon Card Certificate Redemption | Troon</Title>
			<Container size="small" class="flex flex-col gap-8">
				<Heading as="h1">Troon Card Certificate Redemption</Heading>

				<p>
					Congratulations on your Troon Card! Please complete the form below so we can get your Troon Card processed and
					you can start playing the Troon Card participating courses. For more information about the Troon Card program,
					please visit <TextLink href="https://www.trooncard.com">www.TroonCard.com</TextLink>.
				</p>

				<Switch>
					<Match when={submission.result}>
						<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
							<Icon name="circle-check" class="text-8xl text-brand" />
							<Heading as="h1" size="h2">
								Request received!
							</Heading>
							<p class="text-lg">
								Thank you for completing the Troon Card Certificate Form. Your Troon Card will be processed within four
								business days and you will receive a Troon Card Welcome Email at that time with all of the program
								details.
							</p>
						</div>
					</Match>
					<Match when>
						<>
							<Formspree action={action}>
								<div class="flex w-full flex-wrap gap-6">
									<TextField name="First name" required class="grow">
										<Label>First name</Label>
										<Input autocomplete="given-name" value={user()?.me.firstName} />
									</TextField>

									<TextField name="Last name" required class="grow">
										<Label>Last name</Label>
										<Input autocomplete="family-name" value={user()?.me.lastName} />
									</TextField>
								</div>

								<TextField name="Email" required>
									<Label>Email address</Label>
									<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
								</TextField>

								<TextField name="Phone number" required>
									<Label>Phone number</Label>
									<Input autocomplete="tel" inputMode="tel" />
								</TextField>

								<TextField name="Street address" required>
									<Label>Street address</Label>
									<Input autocomplete="street-address" />
								</TextField>

								<div class="flex w-full justify-stretch gap-4">
									<TextField name="City" class="grow" required>
										<Label>City</Label>
										<Input autocomplete="address-level2" />
									</TextField>
									<TextField name="State" class="grow" required>
										<Label>State</Label>
										<Input autocomplete="address-level1" />
									</TextField>
									<TextField name="Postal Code" class="grow" required>
										<Label>Postal Code</Label>
										<Input autocomplete="postal-code" />
									</TextField>
								</div>

								<TextField name="Troon Rewards Number">
									<Label>Troon Rewards number</Label>
									<Input />
									<FieldDescription>If applicable.</FieldDescription>
								</TextField>

								<TextField name="Troon Card Type" required>
									<Label>Troon Card type</Label>
									<Input />
									<FieldDescription>Located on your certificate.</FieldDescription>
								</TextField>

								<TextField name="Certificate Number" required>
									<Label>Certificate number</Label>
									<Input />
									<FieldDescription>Located on your certificate.</FieldDescription>
								</TextField>

								<Checkbox
									name="Terms & Conditions agreed"
									value="Yes"
									onChange={(e) => {
										setAgreed(e.currentTarget.checked);
									}}
								>
									<Label>
										By submitting this form, Cardholder agrees to the Troon Card Terms & Conditions, located here. Troon
										Card may not be extended, modified or sold. Your Troon Card will be processed within four business
										days and you will receive a Troon Card Welcome Email at that time with all of the program details.
									</Label>
								</Checkbox>

								<Errors />
								<Button type="submit" class="w-fit" disabled={!agreed()}>
									Submit
								</Button>
							</Formspree>
						</>
					</Match>
				</Switch>
			</Container>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'certificate-redemption');
